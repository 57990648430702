import { type Image } from 'models';
import { uploadImage } from 'common/queries';
import { OrganizationSelect, type OrganizationOption } from 'features/organizations';
import {
  DateTimeInput,
  Form,
  ImageInput,
  type ImageUploadedData,
  ModalFormActions,
  RichTextInput,
  TextInput,
  Title,
  Row,
  Col,
} from 'ui';

export type CreateVideoFormValues = {
  title: string;
  htmlDescription: string;
  description: string;
  organizationId: OrganizationOption;
  recordedAt: string;
  coverPhoto: ImageUploadedData<Image>;
  // TODO: add more fields
};

type P = {
  isLoading: boolean;
  onSubmit: (data: CreateVideoFormValues) => void;
};

const CreateVideoContent = ({ isLoading, onSubmit }: P) => {
  return (
    <Form<CreateVideoFormValues> onFinish={onSubmit}>
      <Row>
        <Col item={{ span: 24 }}>
          <Title level={4}>Basic information</Title>
        </Col>

        <Col item={{ span: 24 }}>
          <ImageInput
            item={{
              name: 'coverPhoto',
              label: 'Hero image',
            }}
            uploadFile={uploadImage}
            size="large"
          />
        </Col>

        <Col item={{ span: 24 }}>
          <TextInput
            item={{
              name: 'title',
              label: 'Title',
              rules: [
                {
                  required: true,
                },
              ],
            }}
          />
        </Col>

        <Col item={{ span: 24 }}>
          <RichTextInput
            text={{ name: 'description' }}
            html={{
              name: 'htmlDescription',
              label: 'Description',
            }}
          />
        </Col>

        <Col item={{ span: 24 }}>
          <Form.Item name="organizationId" label="Organization" rules={[{ required: true }]}>
            <OrganizationSelect />
          </Form.Item>
        </Col>

        <Col item={{ span: 24 }}>
          <DateTimeInput
            item={{
              name: 'recordedAt',
              label: 'Recorded at',
              rules: [
                {
                  required: true,
                },
              ],
            }}
            input={{
              format: 'MMM D, YYYY [at] h:mm A',
              showTime: { format: 'h:mm A' },
              allowPast: true,
            }}
          />
        </Col>

        <Col item={{ span: 24 }}>
          <ModalFormActions submit={{ children: 'Save', loading: isLoading }} />
        </Col>
      </Row>
    </Form>
  );
};

export default CreateVideoContent;
