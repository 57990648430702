import { setProvidedApiClient, UploadFile } from '@pba/media-chunked-upload';
import { createContext, useContext, useCallback, useMemo, useState, type ReactNode } from 'react';
import { mediaModuleClient } from 'common/services/media-module';

export type { UploadFile, UploadSubscriber } from '@pba/media-chunked-upload';

export type ContentType = 'co';
export type VideoType = 'trailer' | 'video';
type UploadContextType = Record<ContentType, Record<string, Record<VideoType, UploadFile>>>;

const UploadContext = createContext<{
  uploads: UploadContextType;
  setUpload: (params: { contentType: ContentType; ref: string; videoType: VideoType }, file: File) => void;
} | null>(null);

setProvidedApiClient({ axiosInstance: mediaModuleClient });

export const UploadProvider = ({ children }: { children: ReactNode }) => {
  const [uploads, setUploads] = useState<UploadContextType>({ co: {} });

  const setUpload = useCallback(
    ({ contentType, ref, videoType }: { contentType: ContentType; ref: string; videoType: VideoType }, file: File) => {
      setUploads((previousUploads) => {
        const newUploads = { ...previousUploads };

        const previousUpload = newUploads[contentType]?.[ref]?.[videoType];
        if (previousUpload && ['initial', 'uploading', 'error'].includes(previousUpload.state.status)) {
          previousUpload.abort();
        }

        const newUploadFile = new UploadFile(file, {
          mimeType: file.type,
          ref,
          type: contentType,
          name: file.name,
        });

        newUploads[contentType] = {
          ...newUploads[contentType],
          [ref]: {
            ...newUploads[contentType]?.[ref],
            [videoType]: newUploadFile,
          },
        };

        newUploadFile.upload();

        return newUploads;
      });
    },
    [],
  );

  const value = useMemo(() => ({ uploads, setUpload }), [uploads, setUpload]);

  return <UploadContext.Provider value={value}>{children}</UploadContext.Provider>;
};

export const useFileUpload = ({
  contentType,
  ref,
  videoType,
}: {
  contentType: ContentType;
  ref: string;
  videoType: VideoType;
}) => {
  const context = useContext(UploadContext);

  if (!context) {
    throw new Error('useFileUpload must be used within an UploadProvider');
  }

  const { uploads, setUpload } = context;

  const startUpload = useCallback(
    (file: File) => {
      setUpload({ contentType, ref, videoType }, file);
    },
    [contentType, ref, videoType, setUpload],
  );

  return useMemo(
    () => ({
      file: uploads[contentType]?.[ref]?.[videoType],
      startUpload,
    }),
    [uploads, contentType, ref, videoType, startUpload],
  );
};
