export * from './mutation';
export * from './useVideosInfiniteQuery';

import { generateQueryKey } from 'query';

type VideosParams = {
  filter?: { search?: string };
  pagination?: { pageSize?: number };
};

export const getVideosInfiniteQueryKey = ({ filter = {}, pagination = {} }: VideosParams) =>
  generateQueryKey(['videos', { filter, pagination }, 'infinite']);
