import { Route, Redirect, Switch } from 'react-router-dom';
// Pages

import { isGamificationEnabled, isDexcomEnabled } from 'common/validators';
// Pages
import { PageCalendarSync } from '../pages/page-calendar-sync';
import { PageWithingsSync } from '../pages/page-withings-sync';
import { PageDexcomAccountSync } from '../pages/page-dexcom-account-sync';
import { PageCalls } from '../pages/page-calls';
import { PageCampaigns } from '../pages/page-campaigns';
import { PageCommunityFeed } from '../pages/page-community-feed';
import { PageCommunityAssets } from '../pages/page-community-assets';
import { PageCommunityCategories } from '../pages/page-community-categories';
import { PageCommunityAbout } from '../pages/page-community-about/PageCommunityAbout';
import { PageCommunityEvents } from '../pages/page-community-events/PageCommunityEvents';
import { PageCommunityMembers } from '../pages/page-community-members/PageCommunityMembers';
import { PageCourseAbout } from '../pages/page-course-about/PageCourseAbout';
import { PageCourseAssets } from '../pages/page-course-assets';
import { PageCourseForm } from '../pages/page-course-form';
import { PageCourseMembers } from '../pages/page-course-members/PageCourseMembers';
import { PageCourseTimeline } from '../pages/page-course-timeline/PageCourseTimeline';
import { PageDonationsHistory } from '../pages/page-campaigns';
import { PageEventAttendees } from '../pages/page-event-attendees/PageEventAttendees';
import { PageEventDetail } from '../pages/page-event-detail/PageEventDetail';
import { PageEvents } from '../pages/page-events/PageEvents';
import { PageFeed } from '../pages/page-feed/PageFeed';
import { PageGlobalSearch } from '../pages/page-global-search';
import { PageHelp } from '../pages/page-help';
import { PageInviteFriends } from '../pages/page-invite-friends';
import { PageMemberProfile } from '../pages/page-member-profile';
import { PageMessagesOld } from '../pages/page-messages';
import { PageNotifications } from '../pages/page-notifications/PageNotifications';
import { PageOrganizationAbout } from '../pages/page-organization-about/PageOrganizationAbout';
import { PageOrganizationsForCategory } from '../pages/page-organizations-for-category';
import { PagePost } from '../pages/page-post';
import { PageKudos } from '../pages/page-kudos';
import { PagePublicCommunityAbout } from '../pages/page-community-about/PagePublicCommunityAbout';
import { PageTranslationSettings } from '../pages/page-translations-settings';
import { PublicPageCampaigns } from '../pages/public-page-campaigns/PublicPageCampaigns';
import { UserChangePassword, UserLanguage } from '../pages/page-profile';
import { PagePoll } from '../pages/page-poll';
import { PageActivity } from '../pages/page-activity';
import { PageBookmarks } from '../pages/page-bookmarks';
import { PageJournal } from '../pages/page-journal';
import {
  PagePasswordLogin,
  PageSignUp,
  PageForgotPassword,
  PageNewPassword,
  PagePublicPost,
  PagePublicEvent,
  PageLeaderboard,
  PagePublicKudos,
  PageRemoveUserAccount,
  PagePasswordlessLogin,
  PageLoginSignpost,
  PageCheckMagicLink,
  PageConsents,
  PageTokenLogin,
  PageSharedEvent,
  PageChannels,
  PageJoinedCommunities,
  PageJoinedPrograms,
  PageChannelsCommunities,
  PageChannelsPrograms,
  ProfileSettingsNotifications,
  PageCustomChannelsDetail,
  PageChannelsEvents,
  PageChannelsCampaigns,
  PageUserProfile,
  PageMemberMessage,
  PageOnboardingTour,
  CALENDAR_PARAMS,
  PageVideos,
} from 'pages';
// Atoms
import { IsAuthenticated, withPermissions } from '../atoms/auth';
import { getAppConfig } from '../atoms/app-config-gate';
import { PageWithingsOrderPost } from 'old/pages/page-withings-order-post';
import { routes, RedirectWithSearch } from 'routes';

const AppRouter = () => {

  const trackGoogleAnalytics = () => {
    if (process.env.NODE_ENV !== 'production') {
      return;
    }

    if (typeof window.gtag === 'function') {
      window.gtag('config', getAppConfig().constants.GOOGLE_GA, {
        page_location: window.location.href,
        page_path: window.location.pathname,
      });
    }
  };
  trackGoogleAnalytics();

  return (
    <Switch>
      <Redirect exact from="/landing" to="/login/magic-link" />
      <Route exact path="/login" component={PageLoginSignpost} />
      <Route path="/token-login" component={PageTokenLogin} />
      <Route path="/sign-up" component={PageSignUp} />
      <Route path="/login/magic-link" component={PagePasswordlessLogin} />
      <Route path="/magic-link/:token?" component={PageCheckMagicLink} />
      <Route path="/login/password" component={PagePasswordLogin} />
      <Route path="/forgot-password" component={PageForgotPassword} />
      <Route path="/new-password/:token" component={PageNewPassword} />
      <Route path="/new-password" component={PageNewPassword} />
      <Route path="/not-found" render={() => 'Not found'} />
      <Route path="/calls/:id/enter" component={PageCalls} />
      {withPermissions(
        [{ scope: 'superadmin' }],
        <Route path={routes.video.index} component={PageVideos} />,
        <Redirect to={routes.index} />,
      )}

      {/* Publicly accessible donations page for given campaign */}
      <Route path="/donate/:campaignId" component={PublicPageCampaigns} />
      {/* Events */}
      <Route exact path="/events/:eventId/enter/:invitationToken" component={PageSharedEvent} />

      {/* Comments backward compatibility */}
      <Redirect
        exact
        from="/campaigns/:campaignId/comments/:commentId/:replyId?"
        to="/campaigns/listing/unknown/:campaignId"
      />

      <IsAuthenticated
        NotAllowed={
          <Switch>
            {/* Communities */}
            <Route path="/communities/:communityId/about" component={PagePublicCommunityAbout} />
            {/* Posts */}
            <Route path="/posts/:postId/(comments)?/:commentId?/:replyId?" component={PagePublicPost} />
            {/* Kudos */}
            <Route path="/kudos/:kudosId" component={PagePublicKudos} />
            {/* Events */}
            <Route path={routes.events.detail(':eventId')} component={PagePublicEvent} />

            <Redirect
              exact
              from="/communities/:communityId"
              to={{
                pathname: '/sign-up',
                state: { referrer: `${window.location.pathname}${window.location.search}` },
              }}
            />
            <Redirect
              to={{
                pathname: '/login',
                state: { referrer: window.referrer && `${window.location.pathname}${window.location.search}` },
              }}
            />
          </Switch>
        }
        Allowed={
          <Switch>
            {/* Google + Azure hack redirectUrl validation */}
            <RedirectWithSearch
              exact
              from={routes.onboardingTour.calendarSyncUrl}
              to={routes.onboardingTour.index}
              additionalSearch={CALENDAR_PARAMS}
            />
            <Route exact path={routes.onboardingTour.index} component={PageOnboardingTour} />
            <Route exact path={routes.consents} component={PageConsents} />

            <Route exact path="/join-first-community" component={PageCommunityCategories} />
            <Route exact path="/join-first-community/:category_ids" component={PageOrganizationsForCategory} />

            <Switch>
              {/* User Profile */}
              <Redirect exact from="/profile" to="/profile/personal-info" />
              <Route exact path="/profile/personal-info" component={PageUserProfile} />
              <Route exact path="/profile/language" component={UserLanguage} />
              <Route exact path="/profile/calendar-sync" component={PageCalendarSync} />
              <Route exact path="/profile/withings-sync" component={PageWithingsSync} />
              {isDexcomEnabled() && (
                <Route exact path="/profile/dexcom-account-sync" component={PageDexcomAccountSync} />
              )}
              <Route exact path="/profile/change-password" component={UserChangePassword} />
              <Route exact path="/profile/remove-account" component={PageRemoveUserAccount} />
              <Route exact path="/profile/settings/notifications" component={UserChangePassword} />
              {/* Settings */}
              <Redirect exact from="/settings" to="/settings/notifications" />
              <Route path="/settings/notifications" component={ProfileSettingsNotifications} />
              <Route path="/settings/translations" component={PageTranslationSettings} />
              {/* Notifications */}
              <Route path="/notifications" component={PageNotifications} />
              {/*Invite friends*/}
              <Route path="/invite" component={PageInviteFriends} />
              {/* Help */}
              <Route path="/help" component={PageHelp} />
              {/* Network */}
              {/* TODO These redirects are for backward compatibility (Channels + My content) */}
              <Redirect exact from="/network/:scope(explore|branded)" to="/channels" />
              <Redirect exact from="/network" to={routes.community.joined} />
              <Redirect exact from="/network/category/:categoryId" to="/channels" />
              <Redirect exact from="/network/joined" to={routes.community.joined} />
              <Redirect exact from="/profile/communities" to={routes.community.joined} />
              {/* Programs */}
              <Route path="/courses/:courseId/about" component={PageCourseAbout} />
              <Route path="/courses/:courseId/members" component={PageCourseMembers} />
              <Route path="/courses/:courseId/timeline" component={PageCourseTimeline} />
              <Route path="/courses/:courseId/assets" component={PageCourseAssets} />

              {/* TODO These redirects are for backward compatibility (Channels + My content) */}
              <Redirect exact from="/courses/:scope(explore|branded)" to="/channels" />
              <Redirect exact from="/courses" to={routes.program.joined} />
              <Redirect exact from="/courses/joined" to={routes.program.joined} />

              <Redirect exact from="/courses/:courseId" to="/courses/:courseId/timeline" />
              <Redirect exact from="/programs/:courseId" to="/courses/:courseId/timeline" />
              {/* Messages */}
              <Redirect exact from="/messages/c" to="/messages" />
              <Redirect exact from="/messages/c/:activeConversationId" to="/messages/chatrooms/:activeConversationId" />
              <Route
                exact
                path="/messages/:chatState(chatrooms)/:activeConversationId"
                render={(props) => <PageMessagesOld {...props} chatState="chatroom" />}
              />
              <Route
                exact
                path={routes.chat.create(':chatState(new)')}
                render={(props) => <PageMessagesOld {...props} chatState="creating" />}
              />
              <Route exact path="/messages" component={PageMessagesOld} />
              {/* Member profile */}
              <Route path={routes.members.message(':memberId')} component={PageMemberMessage} />
              <Route path={routes.members.detail(':memberId')} component={PageMemberProfile} />
              {/* Events */}
              <Route path={routes.events.detail(':eventId')} component={PageEventDetail} />
              <Route path="/events/:eventId/comments/:commentId?/:replyId?" component={PageEventDetail} />
              <Route path="/events/:eventId/attendees" component={PageEventAttendees} />
              {/* <Route path="/events/:eventId/files" component={PageEventFiles} /> */}
              {/* <Route path="/events/post/create/:eventId" component={PageEventsPostCreate} /> */}
              <Route path="/events/:scope(Past|Upcoming)" component={PageEvents} />
              <Redirect exact from="/events" to="/events/Upcoming" />
              <Redirect exact from="/events/:eventId" to={routes.events.detail(':eventId')} />
              {/* Campaigns */}
              <Redirect exact from="/campaigns" to="/campaigns/listing" />
              <Route exact path="/campaigns/payment-history" component={PageDonationsHistory} />
              <Route exact path="/campaigns/listing/:organizationId?" component={PageCampaigns} />
              <Route path="/campaigns/listing/:organizationId/:campaignId?" component={PageCampaigns} />
              {/* Organizations */}
              <Route path="/organizations/:organizationId/about" component={PageOrganizationAbout} />
              {/* Communities */}
              <Route path="/communities/:communityId/about" component={PageCommunityAbout} />
              <Route path="/communities/:communityId/assets" component={PageCommunityAssets} />
              <Route path="/communities/:communityId/members" component={PageCommunityMembers} />
              <Redirect exact path="/communities/:communityId/events/:scope" to="/communities/:communityId/events" />
              <Route path="/communities/:communityId/events" component={PageCommunityEvents} />
              <Route path="/communities/:communityId/feed" component={PageCommunityFeed} />
              <Route path="/communities/:communityId" component={PageCommunityFeed} />
              <Redirect exact from="/communities/:communityId" to="/communities/:communityId/feed" />
              {/* Feed */}
              <Route path="/feed" component={PageFeed} />
              <Route path="/search/:scope" component={PageGlobalSearch} />
              <Redirect exact from="/" to="/feed" />
              {/* Bookmarks */}
              <Route path="/bookmarks" component={PageBookmarks} />
              {/* Journal */}
              <Route path="/journal" component={PageJournal} />
              {/* Posts */}
              <Route path="/posts/:postId/(comments)?/:commentId?/:replyId?" component={PagePost} />
              {/* Kudos */}
              <Route path="/kudos/:kudosId/(comments)?/:commentId?/:replyId?" component={PageKudos} />
              {/* Polls */}
              <Route path="/polls/:postId/(comments)?/:commentId?/:replyId?" component={PagePoll} />
              {/* Activities */}
              <Route path="/activities/:postId/(comments)?/:commentId?/:replyId?" component={PageActivity} />
              {/* Withings Order */}
              <Route
                path="/withings_order_posts/:orderPostId/(comments)?/:commentId?/:replyId?"
                component={PageWithingsOrderPost}
              />
              {/* Joined Communities */}
              <Route path={routes.community.joined} component={PageJoinedCommunities} />
              {/* Leaderboard */}
              {isGamificationEnabled() && <Route path="/leaderboard" component={PageLeaderboard} />}
              {/* Create Program */}
              <Route path={routes.program.create} component={PageCourseForm} />
              {/* Update Program */}
              <Route path={routes.program.update(':programId')} component={PageCourseForm} />
              {/* Joined Programs */}
              <Route path={routes.program.joined} component={PageJoinedPrograms} />

              {/* Deprecated and remove in 2.8.0+ with routes */}
              <Redirect exact from={routes.channels.createProgram(':organizationId')} to={routes.program.create} />
              <Redirect
                exact
                from={routes.channels.updateProgram(':organizationId', ':programId')}
                to={routes.program.update(':programId')}
              />
              {/* Deprecated ended */}

              {/* Channels Communities */}
              <Route
                path={routes.channels.organizationChannel(':organizationId', 'communities')}
                component={PageChannelsCommunities}
              />
              {/* Channels Programs */}
              <Route
                path={routes.channels.organizationChannel(':organizationId', 'programs')}
                component={PageChannelsPrograms}
              />
              {/* Channels Events */}
              <Route
                path={routes.channels.organizationChannel(':organizationId', 'events')}
                component={PageChannelsEvents}
              />
              {/* Channels Campaigns */}
              <Route
                path={routes.channels.organizationChannel(':organizationId', 'campaigns')}
                component={PageChannelsCampaigns}
              />
              {/* Custom Channels Detail */}
              <Route path={routes.channels.customChannelDetail(':channelId')} component={PageCustomChannelsDetail} />
              {/* Channels */}
              <Route path="/channels" component={PageChannels} />
              {/* Not found */}
              <Redirect to="/not-found" />
            </Switch>
          </Switch>
        }
      />
    </Switch>
  );
};

export default AppRouter;
