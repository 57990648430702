import { message, Modal } from 'ui';
import { useCreateVideo } from '../../queries';
import { CreateVideoContent, type CreateVideoFormValues } from './CreateVideoContent';

type P = {
  isOpened: boolean;
  onCancel: () => void;
};

const CreateVideoModal = ({ isOpened, onCancel }: P) => {
  const { mutate: createVideo, isLoading } = useCreateVideo();

  const onSubmit = (data: CreateVideoFormValues) => {
    createVideo(
      {
        title: data.title,
        organizationId: data.organizationId.value,
        textContent: data.description,
        htmlContent: data.htmlDescription,
        recordedAt: data.recordedAt,
        coverPhoto: data.coverPhoto,
      },
      {
        onSuccess() {
          // TODO: its not exacly video created so we need to change it maybe, ask TK
          message.success('Video created');
          onCancel();
        },
        onError() {
          message.error('Video creation failed');
        },
      },
    );
  };

  return (
    <Modal title="Create video" isOpened={isOpened} onCancel={onCancel} destroyOnClose disableBack>
      <CreateVideoContent isLoading={isLoading} onSubmit={onSubmit} />
    </Modal>
  );
};

export default CreateVideoModal;
