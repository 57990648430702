import { parseISO } from 'date-fns';
import { type Image } from './image';
import { type VideoListItemDataObject } from './service/video';

// TODO: this is only for now, will be changed when we have more data for video
export type VideoSlim = VideoListItem;

// TODO: Implement VideoBase with additional properties if needed
export type VideoBase = VideoSlim;

// TODO: Implement VideoList with additional properties if needed
export type VideoListItem = {
  id: string;
  title: string;
  coverPhoto?: Image;
  recordedAt: Date;
  trailer?: {
    externalId: string;
  };
};

export const createVideoListItem = (data: VideoListItemDataObject): VideoListItem => {
  return {
    id: data.id,
    title: data.title,
    coverPhoto: data.cover_photo,
    recordedAt: parseISO(data.recorded_at),
    trailer: data.trailer ? { externalId: data.trailer.external_id } : undefined,
  };
};

// TODO: Implement VideoDetail with additional properties
export type VideoDetail = VideoListItem & {
  // Add properties specific to VideoDetailDataObject
};
